import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { interactionTypes } from "./dtos/interaction-types.enum";
import { GetUnreadResponseDto } from "./dtos/unread.dto";
import { BlogInteraction } from "./dtos/interaction.dto";
import { catchError, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BlogInteractionsService {
  constructor(private httpService: HttpClient) {}

  getUnread() {
    return this.httpService
      .get<GetUnreadResponseDto>("/api/blog/unread")
      .pipe(catchError(() => of({ count: 0, unreadIds: [] })));
  }

  addBlogPostInteraction(interactionType: interactionTypes, blogId: number) {
    return this.httpService.post<BlogInteraction>("/api/blog/interactions", {
      interactionType,
      blogId,
    });
  }
}
